import styled from "@emotion/styled"
import React from "react"
import { theme } from "../../theme"

const Container = styled.div`
  max-width: 600px;
  margin: calc(2 * ${theme.fontSizes[5]}) auto;
`

const Responsive = styled.div`
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
`

export const Video = ({ videoSrcURL, videoTitle }) => (
  <Container>
    <Responsive>
      <iframe
        src={videoSrcURL}
        title={videoTitle}
        rel="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        frameBorder="0"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        allowFullScreen
      />
    </Responsive>
  </Container>
)
