import styled from "@emotion/styled"
import React, { useEffect, useRef, useState } from "react"
import { FaTwitter } from "react-icons/fa"
import { theme } from "../../theme"
import { Tweet } from "../basic/tweet-button"

const Container = styled.div`
  margin: calc(2 * ${theme.fontSizes[5]}) 0 0.5em;

  blockquote {
    text-align: justify;
    padding: 0 2.5em;
    margin-block-start: 0;

    &::before {
      font-size: 6em;
      top: -0.1em;
    }

    &::after {
      display: none;
    }
  }

  p {
    display: inline;
    font-size: ${theme.fontSizes[4]};
    font-weight: ${theme.fontWeights.bold};
  }
`

const Tweetable = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;
  margin-left: 4rem;

  svg {
    margin-right: 0.2rem;
  }
`

const TITLE = {
  en: "Tweet this",
  ja: "ツイート",
}

export const Blockquote = ({
  cite,
  tweetSlug,
  tweetText,
  tweetHashtags = "",
  lang = "en",
  children,
}) => {
  const isTweetable = tweetSlug || tweetText

  const [textToTweet, setTextToTweet] = useState()
  const contentRef = useRef()

  const hashtags = tweetHashtags.split(/\s*,\s*/)

  useEffect(() => {
    const text = contentRef.current.innerText
    setTextToTweet(tweetText || text)
  }, [tweetText])

  return (
    <Container>
      <blockquote ref={contentRef}>
        <p>{children}</p>
        {cite && <cite>{cite}</cite>}
      </blockquote>
      {isTweetable && (
        <Tweetable>
          <FaTwitter />
          <Tweet
            text={textToTweet}
            slug={tweetSlug}
            hashtags={hashtags}
            title={TITLE[lang]}
          />
        </Tweetable>
      )}
    </Container>
  )
}
