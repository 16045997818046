import React from "react"
import styled from "@emotion/styled"
import LogoSVG from "../../content/assets/logo.inline.svg"

const LogoImage = styled(LogoSVG)`
  width: 4rem;
  height: 100%;
`

export const Logo = () => {
  return <LogoImage />
}
