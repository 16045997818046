const COLORS = {
  WHITE: "#ffffff",
  CREAMY: "#fff4e4",
  BLACK: "#121212",
  BLUE: "#6877b8",
  BLUE_LIGHT: "#bbcff3",
  BLUE_LIGHTER: "#cdddf6",
  BLUE_DARK: "#2f2542",
  PURPLE_DARK: "#602b57",
  GRAY_DARK: "#282936",
  GRAY_LIGHT: "#eeeeee",
  PINK: "#cc6699",
  PINK_DARK: "#b79289",
}

const colorTheme = toCSSVars({
  text: COLORS.BLACK,
  textLight: COLORS.WHITE,
  backgroundLight: COLORS.BLUE_LIGHT,
  backgroundDark: COLORS.BLUE,
  backgroundSecondary: COLORS.CREAMY,
  foreground: COLORS.BLUE_LIGHTER,
  primary: COLORS.BLUE_DARK,
  secondary: COLORS.PURPLE_DARK,
  highlight: COLORS.PINK,
  highlightDark: COLORS.PINK_DARK,
  gray: COLORS.GRAY_DARK,
  success: "#4BB543",
  error: "#E93F36",
})

export const theme = {
  baseSize: ["12px", "16px"],

  sizes: {
    pageWidth: "80rem",
  },

  fonts: {
    body: [
      "system-ui",
      "-apple-system",
      "BlinkMacSystemFont",
      "Segoe UI",
      "Roboto",
      "Helvetica Neue",
      "sans-serif",
    ],
    heading: ["Nunito", "sans-serif"],
  },

  fontSizes: [
    "0.8rem",
    "1rem",
    "1.25rem",
    "1.563rem",
    "1.653rem",
    "2.241rem",
    "3.052rem",
  ],

  fontWeights: {
    body: 300,
    heading: 600,
    bold: 700,
  },

  lineHeights: {
    body: 1.65,
    heading: [1.125, 1.125, 1.3],
  },

  colors: {
    shadow: COLORS.BLUE,
    ...colorTheme.theme,
  },
}

export const cssVars = colorTheme.css

function toCSSVars(themeProps) {
  return Object.entries(themeProps)
    .map(([key, value]) => [key, `--${toKebabCase(key)}`, value])
    .reduce(
      ({ css = {}, theme = {} }, [key, cssVar, value]) => ({
        css: { ...css, [cssVar]: value },
        theme: { ...theme, [key]: `var(${cssVar})` },
      }),
      {}
    )
}

function toKebabCase(camelCase) {
  return camelCase.match(/[A-Z][a-z]+|[a-z]+/g).join("-")
}
