import { css } from "@emotion/core"
import { cssVars, theme } from "../theme"

export const highlightBackgroud = css`
  background: linear-gradient(180deg, #fff3e3 0%, #ffe8d9 100%);
`

export const button = css`
  cursor: pointer;
  padding: 0.3rem;
  min-width: 10rem;
  border: 5px solid ${theme.colors.highlight};
  border-radius: 2px;
  color: ${theme.colors.highlight};
  font-weight: ${theme.fontWeights.bold};
  background: none;
  font-weight: 800;
  text-transform: uppercase;
  text-align: center;

  &:hover,
  &.active {
    color: ${theme.colors.secondary};
    border-color: ${theme.colors.secondary};
  }
`

export const fullscreen = css`
  width: 100%;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  @supports (-webkit-touch-callout: none) {
    min-height: -webkit-fill-available;
  }
`

export const global = css`
  html {
    ${cssVars}

    font-size: ${theme.baseSize[0]};
    font-family: ${theme.fonts.body.join(",")};
    font-weight: ${theme.fontWeights.body};
    line-height: ${theme.lineHeights.body};
    text-rendering: optimizelegibility;
    -webkit-font-smoothing: antialiased;

    @media (min-width: ${theme.sizes.contentWidth}) {
      font-size: ${theme.baseSize[1]};
    }
  }

  body {
    color: ${theme.colors.text};
    background: "#fffaf2";
    transition: color, background-color 0.2s ease-in-out;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: ${theme.fonts.heading.join(",")};
    font-weight: ${theme.fontWeights.heading};
    line-height: ${theme.lineHeights.heading[0]};
    margin: 2em 0 0.5em;
  }

  h1 {
    font-size: ${theme.fontSizes[6]};
    font-weight: 900;
    margin-top: 0.5em;
    color: ${theme.colors.textLight};
    text-transform: uppercase;
  }

  h2 {
    font-size: ${theme.fontSizes[5]};
    line-height: ${theme.lineHeights.heading[1]};
    color: ${theme.colors.highlightDark};
  }

  h3 {
    font-size: ${theme.fontSizes[4]};
    line-height: ${theme.lineHeights.heading[2]};
    color: ${theme.colors.highlightDark};
  }

  h4 {
    font-size: ${theme.fontSizes[3]};
    line-height: ${theme.lineHeights.heading[3]};
  }

  h5 {
    font-size: ${theme.fontSizes[2]};
  }

  strong {
    font-weight: ${theme.fontWeights.bold};
  }

  p {
    margin: 1.5rem 0;
  }

  p,
  ul,
  ol,
  div {
    font-size: ${theme.baseSize[1]};
  }

  ul {
    padding-inline-start: 2.5rem;
  }

  blockquote {
    font-size: ${theme.fontSizes[4]};
    text-align: center;
    position: relative;
    overflow: hidden;
    padding: 0 1.5em;
    margin-block-start: 1rem;
    margin-block-end: 1rem;
    margin-inline-start: 0;
    margin-inline-end: 0;

    &:before,
    &:after {
      position: absolute;
      font-family: ${theme.fonts.heading.join(",")};
      color: ${theme.colors.secondary};
      font-size: 4em;
      height: 100%;
      line-height: 1;
    }

    &:before {
      content: "“";
      left: 0;
    }

    &:after {
      content: "”";
      right: 0;
    }
  }

  a {
    color: ${theme.colors.text};
    text-decoration: none;
    border-bottom: 1px solid ${theme.colors.primary};

    &:hover,
    &.active {
      border-bottom-color: ${theme.colors.highlight};
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;

    @media (max-width: ${theme.sizes.contentWidth}) {
      display: block;

      tr {
        display: flex;
        flex-wrap: wrap;

        justify-content: space-between;
      }

      td {
        display: block;

        &:first-of-type {
          width: 100%;
        }
      }

      th:first-of-type {
        display: none;
      }
    }
  }

  tr {
    border-bottom: 1px solid var(--text);
  }

  td,
  th {
    padding: 0.5rem;
  }

  label {
    font-size: ${theme.fontSizes[1]};
    font-weight: ${theme.fontWeights.bold};
    margin-bottom: 0.25rem;
  }

  input,
  textarea {
    border: 1px solid transparent;
    border-radius: 0;
    padding: 0.5rem;
    margin-bottom: 0.75rem;
    background: ${theme.colors.foreground};

    &:focus {
      outline: 0;
      border: 1px solid ${theme.colors.backgroundDark};
    }
  }

  textarea {
    min-height: 10rem;
    resize: vertical;
  }

  .button,
  input[type="submit"] {
    ${button}
  }
`
