import { Global } from "@emotion/core"
import styled from "@emotion/styled"
import { MDXProvider } from "@mdx-js/react"
import React from "react"
import { useSiteMetadata } from "../hooks/use-site-metadata"
import { fullscreen, global } from "../styles/global"
import { theme } from "../theme"
import { Header } from "./header"
import { Blockquote } from "./widgets/blockquote"
import { Video } from "./widgets/video"

const Wrapper = styled.div`
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
`

export const Row = styled.div`
  margin: 0 auto;
  padding: 0 1rem;
  max-width: ${theme.sizes.pageWidth};
`

const Main = styled.main`
  display: flex;
  flex: 1;
  flex-direction: column;
`

export const Footer = styled.footer`
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

const CopyrightText = styled.small`
  font-size: 0.75rem;
  color: ${theme.colors.textLight};
`

export const Copyright = ({ className }) => {
  const { title } = useSiteMetadata()
  const year = new Date().getFullYear()

  return (
    <CopyrightText className={className}>
      © {title} {year}
    </CopyrightText>
  )
}

const DefaultFooterFn = () => {
  return (
    <Footer>
      <Copyright />
    </Footer>
  )
}

export const Layout = ({ children, footer = DefaultFooterFn }) => {
  const shortcodes = { Blockquote, Video }

  return (
    <>
      <Global styles={global} />

      <Wrapper>
        <div>
          <Row>
            <Header />
          </Row>
        </div>
        <Main>
          <MDXProvider components={shortcodes}>{children}</MDXProvider>
        </Main>
      </Wrapper>

      {footer()}
    </>
  )
}

export const Page = styled.div`
  ${fullscreen}
`
