import styled from "@emotion/styled"
import { Link } from "gatsby"
import React from "react"
import { useSiteMetadata } from "../hooks/use-site-metadata"
import { theme } from "../theme"
import { Logo } from "./logo"
import { Menu } from "./menu"

const StyledHeader = styled.header`
  position: relative;
  overflow: hidden;
  padding: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const TitleLink = styled(Link)`
  display: flex;
  align-items: center;
  border: 0;

  div {
    font-family: ${theme.fonts.heading.join(",")};
    margin-left: 1.5rem;

    @media (max-width: 600px) {
      display: none;
    }
  }
`

export const Header = () => {
  const { title } = useSiteMetadata()

  return (
    <StyledHeader>
      <TitleLink to="/">
        <Logo />
        <div>{title}</div>
      </TitleLink>
      <Menu />
    </StyledHeader>
  )
}
