import styled from "@emotion/styled"
import { Link } from "gatsby"
import React from "react"
import { useMenu } from "../hooks/use-menu"
import { theme } from "../theme"

const Scrollable = styled.div`
  overflow: hidden;

  nav {
    white-space: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`

const Nav = styled.nav`
  ul {
    padding-inline-start: 0;
    display: flex;
    list-style-type: none;
    align-items: center;
    margin: 0;
    padding: 0;
  }

  li {
    margin-left: 3rem;
    margin-bottom: 0;

    &:last-of-type {
      position: relative;
      z-index: 10;
    }

    &:first-of-type {
      margin-left: 0.75rem;
    }

    @media (max-width: 500px) {
      margin-left: 1.5rem;
    }
  }

  a {
    color: ${theme.colors.primary};
    border-bottom-width: 0;
    &:hover,
    &.active {
      color: ${theme.colors.text};
      border-bottom-width: 2px;
    }
  }
`

export const Menu = ({ className }) => {
  const menu = useMenu()

  return (
    <Scrollable>
      <Nav className={className}>
        <ul>
          {menu.map(({ value, slug }) => (
            <li key={slug}>
              <Link to={slug} activeClassName="active">
                {value}
              </Link>
            </li>
          ))}
        </ul>
      </Nav>
    </Scrollable>
  )
}
